import type {Risk, RiskDeviation, RiskLevel} from '~/lib/types/riskTypes';
import type {BadgeClass} from '~/components/generic/badge/BaseBadge.vue';

export const getRiskLevel = (risk: {
	level: RiskLevel;
	levelDeviation?: RiskDeviation;
	levelDeviationText?: RiskLevel;
}) => {
	return risk.levelDeviationText ?? risk.levelDeviation?.level ?? risk.level;
};

const assertNever = (riskLevel: never) => `Ontbreekt: ${riskLevel}`;

export const getIrrelevancyText = (state: Risk['state']) => {
	if (state === 'RELEVANT') {
		return '';
	}
	switch (state) {
		case 'IRRELEVANT_THROUGH_ANSWER':
			return 'Dit risico is mogelijk niet meer van toepassing door het antwoord op de bijbehorende vraag tijdens de inventarisatie.';
		case 'IRRELEVANT_THROUGH_DISABLED_SECTOR':
			return 'Dit risico is mogelijk niet meer van toepassing, omdat de vraag onderdeel is van een onderwerp dat niet relevant is voor de geselecteerde sectoren.';
		case 'IRRELEVANT_THROUGH_DISABLED_TOPIC':
			return 'Dit risico is mogelijk niet meer van toepassing, omdat de vraag onderdeel is van een onderwerp dat uitgeschakeld is.';
		case 'ARCHIVED':
			return 'Dit risico is mogelijk niet meer van toepassing, omdat het risico door de branche organisatie gearchiveerd is.';
		default:
			throw new Error(`Unknown irrelevancy state: ${state}`);
	}
};

/**
 * Get the Dutch text for a risk level.
 * @param riskLevel
 */
export const getRiskLevelText = (riskLevel: Risk['level']): string => {
	switch (riskLevel) {
		case 'LOW':
			return 'Laag';
		case 'MEDIUM':
			return 'Midden';
		case 'HIGH':
			return 'Hoog';
		default:
			throw new Error('Invalid risk level');
	}
};

/**
 * Get the text for a RiskLevel based on a FineAndKinney risk score.
 * @param exposureNum
 * @param effectNum
 * @param probabilityNum
 */
export const getRiskScoreLevel = (exposureNum: number, effectNum: number, probabilityNum: number): Risk['level'] | null => {

	if (!isNaN(exposureNum) && !isNaN(effectNum) && !isNaN(probabilityNum)) {
		const riskScore = exposureNum * effectNum * probabilityNum;
		if (riskScore >= 200) return 'HIGH';
		if (riskScore >= 70) return 'MEDIUM';
		return 'LOW';
	}
	return null;
};

export const getRiskLevelBadgeColor = (riskLevel: Risk['level']): BadgeClass => {
	switch (riskLevel) {
		case 'LOW':
			return 'risk-low';
		case 'MEDIUM':
			return 'risk-medium';
		case 'HIGH':
			return 'risk-high';
		default:
			throw new Error('Invalid risk level');
	}
};

const getNumberFromLevel = (level: Risk['level']) => {
	switch (level) {
		case 'LOW':
			return 1;
		case 'MEDIUM':
			return 2;
		case 'HIGH':
			return 3;
		default:
			throw new Error('Invalid risk level');
	}
};

type SortLevelRisk = Pick<Risk, 'level' | 'levelDeviation'>
export const sortLevel = (riskA: SortLevelRisk, riskB: SortLevelRisk, sortingDirection: 'asc' | 'desc' | null) => {
	if (sortingDirection === null) {
		return 0;
	}
	const a = getNumberFromLevel(getRiskLevel(riskA));
	const b = getNumberFromLevel(getRiskLevel(riskB));
	const result = Math.min(Math.max(a - b, -1), 1) as -1 | 0 | 1;
	return sortingDirection === 'asc' ? result : result * -1 as -1 | 0 | 1;
};

type RootCause = 'BEHAVIORAL' | 'ORGANIZATIONAL' | 'TECHNICAL' | 'SKILLFUL';

export const getRootCauseLabel = (rootCause: RootCause) => {
	switch (rootCause) {
		case 'BEHAVIORAL':
			return 'Cultuur en Gedrag';
		case 'ORGANIZATIONAL':
			return 'Organisatie';
		case 'TECHNICAL':
			return 'Techniek en Materieel';
		case 'SKILLFUL':
			return 'Kennis en Vaardigheden';
		default:
			return assertNever(rootCause);
	}
};

export const rootCauseOptions: Array<{ value: RootCause, label: string }> = [
	{value: 'ORGANIZATIONAL', label: getRootCauseLabel('ORGANIZATIONAL')},
	{value: 'TECHNICAL', label: getRootCauseLabel('TECHNICAL')},
	{value: 'SKILLFUL', label: getRootCauseLabel('SKILLFUL')},
	{value: 'BEHAVIORAL', label: getRootCauseLabel('BEHAVIORAL')}
];
